import { IResearch } from "../../interfaces/research";
import EmptyData from "../EmptyData";
import ResearchItem from "../ResearchArchiveItem";

const ResearchArchiveItems = ({ researches }: { researches: IResearch[] }) => {
  return researches.length ? (
    <div className="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 grid-cols-1 sm:gap-x-4 xl:gap-x-14">
      {researches.map((research) => (
        <ResearchItem research={research} />
      ))}
    </div>
  ) : (
    <div className="w-full flex justify-center">
      <EmptyData />
    </div>
  );
};
export default ResearchArchiveItems;
