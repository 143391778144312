import { useHistory } from "react-router-dom";
import { openWindowWithBlankPage } from "../../helper/open.window";
import SSODBSVService from "../../services/sso-dbsv.service";
import Title from "./Title";
import { PATHS } from "../../const/path";
import { useTranslation } from "react-i18next";

const InvestmentTool = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-y-2 px-4">
      <Title title={t("eService.investmentTool.title")} />
      <div className="flex justify-between gap-x-2">
        <InvestmentToolCard
          Image={<BrainBoxTechnical />}
          label="BrainBox"
          detail="(Technical Insight)"
          onClick={() => {
            new SSODBSVService()
              .getBrainBoxTILink()
              .then((link) =>
                openWindowWithBlankPage({ link, isPopup: false })
              );
          }}
        />
        <InvestmentToolCard
          Image={<BrainBoxFundamental />}
          label="BrainBox"
          detail="(Fundamental Insight)"
          onClick={() => {
            new SSODBSVService()
              .getBrainBoxVALink()
              .then((link) =>
                openWindowWithBlankPage({ link, isPopup: false })
              );
          }}
        />
        <InvestmentToolCard
          Image={<WealthBox />}
          label="WealthBOX"
          onClick={() => {
            new SSODBSVService()
              .getWealthBoxLink()
              .then((link) =>
                openWindowWithBlankPage({ link, isPopup: false })
              );
          }}
        />
        <InvestmentToolCard
          Image={<Research />}
          label={t("eService.investmentTool.research")}
          onClick={() => history.push(PATHS.E_SERVICE_RESEARCH)}
        />
      </div>
    </div>
  );
};

export default InvestmentTool;

const InvestmentToolCard = ({
  Image,
  label,
  detail,
  onClick,
}: {
  Image: JSX.Element;
  label: string;
  detail?: string;
  onClick: () => void;
}) => {
  return (
    <div
      onClick={onClick}
      className="bg-white p-2 rounded-xl flex flex-col items-center text-2xs w-1/4 text-center gap-y-1 cursor-pointer"
    >
      <ImageBox>{Image}</ImageBox>
      <span className="font-bold">{label}</span>
      {!!detail && <span>{detail}</span>}
    </div>
  );
};

const ImageBox = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <div
      className="rounded-20px border border-dbsv-neutral-slate-10 flex justify-center items-center"
      style={{ height: 60, width: 60 }}
    >
      {children}
    </div>
  );
};

const BrainBoxTechnical = () => (
  <img
    src="/images/e-service/BrainBoxTechLogo.png"
    alt="brain-box-logo"
    className="ml-1 h-9"
  />
);

const BrainBoxFundamental = () => (
  <img
    src="/images/e-service/BrainBoxFundamentalLogo.png"
    alt="brain-box-logo"
    className="ml-1 h-9"
  />
);

const WealthBox = () => (
  <img
    src="/icons/my-favorite/WealthBOX.png"
    alt="brain-box-logo"
    className="h-12"
  />
);

const Research = () => (
  <img src="/images/research-icon.png" alt="brain-box-logo" className="h-9" />
);
