/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { filterAccount } from "../../helper/accounts";
import { getDateInFormat } from "../../helper/date.time";
import { ICashMovement } from "../../interfaces/cash.movement";
import { IMonthlyReport } from "../../interfaces/monthly.report.list";
import { IStockMovement } from "../../interfaces/stock.movement";
import { IStockStatement } from "../../interfaces/stock.statement";
import ReportService from "../../services/report.service";
import { CashMovementDetail } from "../CashMovementDetail";
import { FilterAccount } from "../FilterAccount";
import LoadingCard from "../LoadingCard";
import ReportTable, { IReportTableBody } from "../ReportTable";
import { StockMovementDetail } from "../StockMovementDetail";
import { StockStatementDetail } from "../StockStatementDetail";

interface IMonthlyReportState {
  accountsMonthlyReport: string[];
  monthlyReports: IMonthlyReport[];
  isLoading: boolean;
  error: string;
}

interface IMonthlyReportAction {
  type: "pending" | "fulfilled" | "rejected";
  payload?: IMonthlyReport[];
  error?: string;
}

const initialState: IMonthlyReportState = {
  accountsMonthlyReport: [],
  monthlyReports: [],
  isLoading: false,
  error: "",
};

const monthReportReducer = (
  state: IMonthlyReportState,
  action: IMonthlyReportAction
): IMonthlyReportState => {
  switch (action.type) {
    case "pending":
      return { ...state, isLoading: true };
    case "fulfilled":
      return {
        ...state,
        isLoading: false,
        monthlyReports: action.payload ?? [],
        accountsMonthlyReport: [
          ...filterAccount(action.payload ?? [], state.accountsMonthlyReport),
        ],
      };
    case "rejected":
      return { ...state, isLoading: false, error: action.error ?? "" };
  }
};

export const MonthlyReport = () => {
  const { t: translate } = useTranslation();
  const [{ accountsMonthlyReport, monthlyReports, isLoading }, dispatch] =
    useReducer(monthReportReducer, initialState);
  const [monthlyReportSelected, setMonthlyReportSelected] =
    useState<IMonthlyReport>({
      accountNo: "",
      asOfDate: "",
      report: "Cash Movement",
    });
  const [openDetail, setOpenDetail] = useState<{
    open: boolean;
    report: "Cash Movement" | "Stock Movement" | "Stock Statement";
  }>({
    open: false,
    report: "Cash Movement",
  });
  const [cashMovement, setCashMovement] = useState<ICashMovement[]>([]);
  const [stockMovement, setStockMovement] = useState<IStockMovement[]>([]);
  const [stockStatement, setStockStatement] = useState<IStockStatement[]>([]);
  const [tableBody, setTableBody] = useState<IReportTableBody[]>([]);
  const reportService = new ReportService();

  useEffect(() => {
    getMonthlyReport();
  }, []);

  useEffect(() => {
    setTableBody([
      ...monthlyReports.reduce((prev, curr) => {
        prev.push({
          datas: [
            { text: curr?.accountNo ?? "-", align: "left" },
            { text: curr?.report ?? "-", align: "left" },
            {
              text: curr?.asOfDate
                ? getDateInFormat(curr?.asOfDate.toString())
                : "-",
              align: "left",
            },
          ],
          action: () => {
            setMonthlyReportSelected(curr);
            getMonthlyReportDetail(curr);
          },
        });
        return prev;
      }, [] as IReportTableBody[]),
    ]);
  }, [monthlyReports, translate]);

  const getMonthlyReport = (accounts?: string[]) => {
    dispatch({ type: "pending" });
    new ReportService()
      .getMonthlyReport({ accounts: accounts ?? [] })
      .then((value) => dispatch({ type: "fulfilled", payload: value }))
      .catch((error) => dispatch({ type: "rejected", error }));
  };

  const getMonthlyReportDetail = async (
    monthlyReportSelected: IMonthlyReport
  ) => {
    if (monthlyReportSelected.report === "Cash Movement") {
      const cashMovement = await reportService.getMonthlyCashMovementDetail(
        monthlyReportSelected
      );
      setCashMovement(cashMovement);
    }
    if (monthlyReportSelected.report === "Stock Movement") {
      const stockMovement = await reportService.getMonthlyStockMovementDetail(
        monthlyReportSelected
      );
      setStockMovement(stockMovement);
    }

    if (monthlyReportSelected.report === "Stock Statement") {
      const stockStatement = await reportService.getMonthlyStockStatementDetail(
        monthlyReportSelected
      );
      setStockStatement(stockStatement);
    }
    setOpenDetail({ open: true, report: monthlyReportSelected.report });
  };

  return (
    <div>
      <div className="flex items-center gap-x-6">
        <FilterAccount
          accounts={accountsMonthlyReport}
          onSelectAccount={(account) => {
            getMonthlyReport(account ? [account] : []);
          }}
        />
      </div>
      {isLoading ? (
        <LoadingCard />
      ) : (
        <ReportTable
          labels={[
            { text: "Account No.", align: "left" },
            { text: "Report", align: "left" },
            { text: "As of Date", align: "left" },
            { text: "Detail", align: "center" },
          ]}
          bodys={tableBody}
          key="mouthly-report"
        />
      )}
      {openDetail.open && openDetail.report === "Cash Movement" && (
        <CashMovementDetail
          closeModal={() => {
            setOpenDetail({ open: false, report: "Cash Movement" });
          }}
          monthlyReport={monthlyReportSelected}
          cashMovements={cashMovement}
        />
      )}
      {openDetail.open && openDetail.report === "Stock Movement" && (
        <StockMovementDetail
          closeModal={() => {
            setOpenDetail({ open: false, report: "Stock Movement" });
          }}
          monthlyReport={monthlyReportSelected}
          stockMovements={stockMovement}
        />
      )}
      {openDetail.open && openDetail.report === "Stock Statement" && (
        <StockStatementDetail
          closeModal={() => {
            setOpenDetail({ open: false, report: "Stock Statement" });
          }}
          monthlyReport={monthlyReportSelected}
          stockStatements={stockStatement}
        />
      )}
    </div>
  );
};
