import ContractNoteHelp from "../components/ContractNoteModal/description";
import EServiceTopbar from "../components/EService/Topbar";
import { ISideBarMenu } from "../interfaces/menu";
import Auth from "../pages/Auth/auth";
import CustomerHandbook from "../pages/CustomerHandbook";
import { Dashboard } from "../pages/dashboard";
import EForms from "../pages/EForm";
import EService from "../pages/EService";
import InvestmentTools from "../pages/investment-tools";
import MyAccount from "../pages/MyAccount";
import { NewDashboard } from "../pages/new-dashboard";
import Research from "../pages/Research";
import ResearchArchive from "../pages/ResearchArchive";
import ResearchPDFView from "../pages/ResearchPDFView";
import { RewardsPage } from "../pages/Rewards";
import SSOCallback from "../pages/ssocallback";
import { StatementReport } from "../pages/statement-report";
import Unauthorize from "../pages/unauthorize";
import { ReactComponent as ChartUpSVG } from "../svgIcon/sidebar/chart-up.svg";
import { ReactComponent as EFormSVG } from "../svgIcon/sidebar/document-pen.svg";
import { ReactComponent as DBSVResearchSVG } from "../svgIcon/sidebar/document-percent.svg";
import { ReactComponent as EStatementSVG } from "../svgIcon/sidebar/e-statement.svg";
import { ReactComponent as RewardSVG } from "../svgIcon/sidebar/giftbox.svg";
import { ReactComponent as HomeSVG } from "../svgIcon/sidebar/home.svg";
import { ReactComponent as HandbookSVG } from "../svgIcon/sidebar/open-book.svg";
import { ReactComponent as StreamingSVG } from "../svgIcon/sidebar/streaming.svg";
import { ReactComponent as MyAccountSVG } from "../svgIcon/sidebar/user.svg";

export const OUT_OF_CONTEXT_PATH = {
  AUTH: "/",
  UNAUTHORIZED: "/unauthorized",
  SSO_CALLBACK: "/callback",
  CONTRACT_NOTE_HELP: "/contract-note/help",
};

export const PATHS = {
  DASHBOARD: "/dashboard",
  STREAMING: "/streaming",
  INVESTMENT_TOOLS: "/investment-tools",
  DBSV_RESEARCH: "/research",
  MY_ACCOUNT: "/my-account",
  E_STATEMENT: "/statement-report",
  E_FORMS: "/e-forms",
  DBSV_REWARDS: "/rewards",
  DBSV_RESEARCH_ARCHIVE: "/research-archive",
  DBSV_RESEARCH_PDF_VIEW: "/research-view",
  CUSTOMER_HANDBOOK: "/customer-handbook",
  E_SERVICE: "/e-service",
  E_SERVICE_RESEARCH: "/e-service-research",
  E_SERVICE_E_STATEMENT: "/e-service-e-statement",
  E_SERVICE_CUSTOMER_HANDBOOK: "/e-service-customer-handbook",
  ...OUT_OF_CONTEXT_PATH,
};

export const PATH_ROUTE: {
  [key: string]: ISideBarMenu;
} = {
  DASHBOARD: {
    Icon: HomeSVG,
    name: "sidebar.dashboard",
    path: PATHS.DASHBOARD,
    component: NewDashboard,
    activePaths: [PATHS.DASHBOARD, PATHS.AUTH],
  },
  STREAMING: {
    Icon: StreamingSVG,
    name: "sidebar.streaming",
    path: PATHS.STREAMING,
    component: Dashboard,
  },
  INVESTMENT_TOOLS: {
    Icon: ChartUpSVG,
    name: "sidebar.investment-tools",
    path: PATHS.INVESTMENT_TOOLS,
    component: InvestmentTools,
  },
  DBSV_RESEARCH: {
    Icon: DBSVResearchSVG,
    name: "sidebar.research",
    path: PATHS.DBSV_RESEARCH,
    component: Research,
    activePaths: [
      PATHS.DBSV_RESEARCH,
      PATHS.DBSV_RESEARCH_ARCHIVE,
      PATHS.DBSV_RESEARCH_PDF_VIEW,
    ],
  },
  E_STATEMENT: {
    Icon: EStatementSVG,
    name: "sidebar.statement",
    path: PATHS.E_STATEMENT,
    component: StatementReport,
  },
  E_FORMS: {
    Icon: EFormSVG,
    name: "sidebar.e-forms",
    path: PATHS.E_FORMS,
    component: EForms,
  },
  DBSV_REWARDS: {
    Icon: RewardSVG,
    name: "sidebar.rewards",
    path: PATHS.DBSV_REWARDS,
    component: RewardsPage,
  },
  CUSTOMER_HANDBOOK: {
    Icon: HandbookSVG,
    name: "sidebar.customer-handbook",
    path: PATHS.CUSTOMER_HANDBOOK,
    component: CustomerHandbook,
  },
  MY_ACCOUNT: {
    Icon: MyAccountSVG,
    name: "sidebar.my-account",
    path: PATHS.MY_ACCOUNT,
    component: MyAccount,
  },
};

export const APP_ROUTE: { [key: string]: ISideBarMenu } = {
  AUTH: {
    name: "page.authentication",
    path: PATHS.AUTH,
    component: Auth,
  },
  DBSV_RESEARCH_ARCHIVE: {
    Icon: DBSVResearchSVG,
    name: "sidebar.research",
    path: PATHS.DBSV_RESEARCH_ARCHIVE,
    component: ResearchArchive,
  },
  DBSV_RESEARCH_PDF_VIEW: {
    Icon: DBSVResearchSVG,
    name: "sidebar.research",
    path: PATHS.DBSV_RESEARCH_PDF_VIEW,
    component: ResearchPDFView,
  },
  UNAUTHORIZED: {
    name: "page.unauthorized",
    path: PATHS.UNAUTHORIZED,
    component: Unauthorize,
  },
  ...PATH_ROUTE,
  SSO_CALLBACK: {
    name: "page.sso-callback",
    path: PATHS.SSO_CALLBACK,
    component: SSOCallback,
  },
  CONTRACT_NOTE_HELP: {
    name: "",
    path: PATHS.CONTRACT_NOTE_HELP,
    component: ContractNoteHelp,
  },
  CUSTOMER_HANDBOOK: {
    name: "page.customer-handbook",
    path: PATHS.CUSTOMER_HANDBOOK,
    component: CustomerHandbook,
  },
  E_SERVICE: {
    name: "page.e-service",
    path: PATHS.E_SERVICE,
    component: EService,
  },
  E_SERVICE_RESEARCH: {
    name: "page.e-service.research",
    path: PATHS.E_SERVICE_RESEARCH,
    component: () => {
      return (
        <>
          <EServiceTopbar />
          <Research />
        </>
      );
    },
  },
  E_SERVICE_E_STATEMENT: {
    name: "page.e-service.e-statement",
    path: PATHS.E_SERVICE_E_STATEMENT,
    component: () => {
      return (
        <>
          <EServiceTopbar />
          <StatementReport />
        </>
      );
    },
  },
  E_SERVICE_CUSTOMER_HANDBOOK: {
    name: "page.e-service.customer-handbook",
    path: PATHS.E_SERVICE_CUSTOMER_HANDBOOK,
    component: () => {
      return (
        <>
          <EServiceTopbar />
          <CustomerHandbook />
        </>
      );
    },
  },
};
