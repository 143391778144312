import { useTranslation } from "react-i18next";
import { SVG_CHEVRON_RIGHT } from "../../const/svg.icon";
import { openWindowWithBlankPage } from "../../helper/open.window";
import SSODBSVService from "../../services/sso-dbsv.service";
import Title from "./Title";

const OverseaInvestment = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-y-2 px-4">
      <Title title={t("eService.overseaTrading.title")} />
      <div
        className="bg-white rounded-xl px-5 py-4 flex justify-between items-center gap-x-5 shadow-e-service-card cursor-pointer"
        onClick={() => {
          new SSODBSVService().getMTradingWebsiteLink().then((link) =>
            openWindowWithBlankPage({
              link,
              isPopup: false,
            })
          );
        }}
      >
        <div className="flex gap-x-5 items-center">
          <img
            className="h-16 w-16"
            src="/images/mTradingTH-Black-1024.png"
            alt="mTrading Logo"
          />
          <div className="flex flex-col gap-y-1">
            <span className="font-bold text-base">
              {t("eService.overseaTrading.mTrading.title")}
            </span>
            <span className="text-10px text-dbsv-neutral-slate-70">
              {t("eService.overseaTrading.mTrading.description")}
            </span>
          </div>
        </div>
        <div className="text-dbsv-neutral-slate-70">{SVG_CHEVRON_RIGHT}</div>
      </div>
    </div>
  );
};

export default OverseaInvestment;
