import { IResearch, IResearchGroupByCategory } from "../../interfaces/research";

export const groupResearchByCategory = (
  researches: IResearch[]
): IResearchGroupByCategory[] => {
  return researches
    .reduce<IResearchGroupByCategory[]>((prev, curr) => {
      const categoryIndex = prev.findIndex(
        (category) =>
          category.research_category.id === curr.research_category.id
      );
      if (categoryIndex >= 0) {
        prev[categoryIndex].researches.push(curr);
      } else {
        prev.push({
          research_category: curr.research_category,
          researches: [curr],
        });
      }
      return prev;
    }, [] as IResearchGroupByCategory[])
    .sort(
      (a, b) => a.research_category.priority - b.research_category.priority
    );
};
