import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../../components/Button";
import { useUserContext } from "../../context/user/user.context";
import { currency } from "../../helper/format.number";
import { openWindow } from "../../helper/open.window";
import { isThaiLanguage } from "../../i18n/helper";
import GenerateLinkService from "../../services/generate-sso-ekyc-link-service";
import { useFeatureToggle } from "../../hooks/features";

export const PointsSummary = () => {
  const { t: translate } = useTranslation();
  const { userRewards } = useUserContext();
  const { redeemReward } = useFeatureToggle();
  const [redemptionLink, setRedemptionLink] = useState<string>("");

  useEffect(() => {
    new GenerateLinkService().getRedemptionLink().then(setRedemptionLink);
  }, []);

  const expiryPoints = currency(userRewards.expiryPoints);
  const pointExpirationDate = new Date(userRewards.pointExpirationDate);
  const pointAsOfDate = new Date(userRewards.asOfDate);

  let pointsAsOfDateLabel = "",
    pointsExpirationLabel = "";

  if (isThaiLanguage()) {
    const dateFormat = new Intl.DateTimeFormat("th-TH", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    pointsAsOfDateLabel = `คะแนนอัพเดทล่าสุด ณ วันที่ ${dateFormat.format(
      pointAsOfDate
    )}`;
    pointsExpirationLabel = `มี ${expiryPoints} คะแนน จะหมดอายุวันที่ ${dateFormat.format(
      pointExpirationDate
    )}`;
  } else {
    const dateFormat = new Intl.DateTimeFormat("en-Au", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    pointsAsOfDateLabel = `As of ${dateFormat.format(pointAsOfDate)}`;
    pointsExpirationLabel = `Point Expiration : ${expiryPoints} pts. on ${dateFormat.format(
      pointExpirationDate
    )}`;
  }

  const onClickRedemption = () =>
    openWindow({ link: redemptionLink, isPopup: false });

  return (
    <div className="flex flex-col justify-between w-full py-6 rounded-lg bg-dbsvRewards-bgCard">
      <div>
        <div className="flex flex-col px-6 border-b border-grey-500">
          <div className="text-xl font-light text-white">
            {translate("rewards.points_summary.title")}
          </div>
          <div className="my-2 text-sm text-gray-400">
            {translate("rewards.points_summary.description")}
          </div>
        </div>
        <div className="relative flex flex-col justify-between px-8 py-6 mx-6 mt-6 text-white rounded-lg bg-dbsvRewards-bgContent gap-y-2">
          <h1 className="text-2xl">
            {currency(userRewards.totalPoints)}{" "}
            {translate("rewards.points_summary.unit")}
          </h1>
          <ul className="z-10 text-sm leading-7">
            <li>{`• ${pointsAsOfDateLabel}`}</li>
            <li>{`• ${pointsExpirationLabel}`}</li>
          </ul>
          <img
            src="/icons/rewards/coin.png"
            className="absolute bottom-0 right-0 w-28"
            alt="coin"
          />
        </div>
      </div>
      <div className="flex justify-end w-full px-6 mt-6">
        {redeemReward && (
          <PrimaryButton
            label={translate("rewards.points_summary.redemption")}
            onClick={onClickRedemption}
          />
        )}
      </div>
    </div>
  );
};
