import { useEffect, useState } from "react";
import Bank from "../components/EService/Bank";
import ContactUs from "../components/EService/ContactUs";
import EStatement from "../components/EService/EStatement";
import InvestmentTool from "../components/EService/InvestmentTool";
import ManageAccount from "../components/EService/ManageAccount";
import OverseaInvestment from "../components/EService/OverseaInvestment";
import RewardPoint from "../components/EService/RewardPoint";
import Language from "../components/TopBar/Language";
import YourMissionPanel from "../components/YourMission/YourMissionPanel";
import MissionService, { IMissions } from "../services/ats.service";

const EService = () => {
  const [missions, setMissions] = useState<IMissions[]>([]);

  useEffect(() => {
    new MissionService().getMissions().then(setMissions);
  }, []);

  return (
    <div className="bg-dbsv-neutral-slate-00">
      <nav className="bg-white py-2 px-4 flex justify-between items-center">
        <img
          id="logo"
          alt="logo-mobile-mode"
          src="/favicon.ico"
          className="h-8 w-8"
        />
        <span className="font-bold">e-Service</span>
        <Language from="e-service" />
      </nav>
      <div className="pt-4 flex flex-col gap-y-6">
        <OverseaInvestment />
        <YourMissionPanel missions={missions} />
        <InvestmentTool />
        <div className="flex flex-col gap-y-2 bg-white">
          <RewardPoint />
          <div className="flex flex-col px-4 gap-y-5 py-3">
            <ManageAccount />
            <Bank />
            <EStatement />
          </div>
          <ContactUs />
        </div>
      </div>
    </div>
  );
};

export default EService;
