import { PageName } from "../../enum/page.name";
import { openWindow } from "../../helper/open.window";
import GenerateLinkService from "../../services/generate-sso-ekyc-link-service";
import SSODBSVService from "../../services/sso-dbsv.service";

export const onOpenEKYC = async () => {
  const generateSSOKYCLinkService = new GenerateLinkService();
  const SSOKYCLink = await generateSSOKYCLinkService.generateSSOKYCLink();
  openWindow({ link: SSOKYCLink });
};

export const onOpenRequestForm = async () => {
  const ssoDBSVService = new SSODBSVService();
  const SSODBSVLink = await ssoDBSVService.getRequestFormLink();
  openWindow({ link: SSODBSVLink });
};

export const isOpenEKYC = (pageName: PageName) =>
  pageName === PageName.ekycSuitTest;

export const onOpenDDR = () => {
  return openWindow({ link: process.env.REACT_APP_DIRECT_DEBIT_REGISTRATION! });
};
