/* eslint-disable react-hooks/exhaustive-deps */
import queryString from "query-string";
import { useEffect } from "react";
import { useHistory } from "react-router";
import Cookies from "universal-cookie";
import { DEFAULT_LANGUAGE, DEFAULT_PAGE } from "../../const/cookie";
import { APP_ROUTE } from "../../const/path";
import { useHandleAPIStateContext } from "../../context/handle-api-state";
import { isAuthorized } from "../../helper/authentication";
import { setLanguage } from "../../i18n/helper";
import AuthenService from "../../services/authen.service";

const Auth = () => {
  const cookie = new Cookies();
  const history = useHistory();
  const { setGettingData }: { setGettingData: (gettingData: boolean) => void } =
    useHandleAPIStateContext();

  useEffect(() => {
    checkAuthorization();
  }, []);

  const checkAuthorization = async () => {
    const queryParams = queryString.parse(window.location.search);
    if (queryParams.code) {
      getAccessToken(queryParams.code as string);
    } else {
      const isAuthorizedResult = await isAuthorized();
      if (isAuthorizedResult) handleRedirect();
      else {
        history.replace(APP_ROUTE.UNAUTHORIZED.path);
      }
    }
    setDefaultLanguage();
  };

  const handleRedirect = () => {
    const defaultPage = cookie.get(DEFAULT_PAGE);
    if (defaultPage) {
      history.replace(defaultPage);
      cookie.remove(DEFAULT_PAGE);
    } else {
      history.replace(APP_ROUTE.DASHBOARD.path);
    }
  };

  const setDefaultLanguage = () => {
    const defaultLanguage = cookie.get(DEFAULT_LANGUAGE);
    if (defaultLanguage) {
      setLanguage(defaultLanguage);
      cookie.remove(DEFAULT_LANGUAGE);
    }
  };

  const getAccessToken = async (code: string) => {
    try {
      setGettingData(true);
      await new AuthenService().generateToken({
        authorizationCode: code,
      });
      handleRedirect();
    } catch (error) {
      history.replace(APP_ROUTE.UNAUTHORIZED.path);
    } finally {
      setGettingData(false);
    }
  };

  return <></>;
};

export default Auth;
